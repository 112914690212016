const translations = window.translations || {};

const translate = (originalWord) => {
  if (Object.prototype.hasOwnProperty.call(translations, originalWord)) {
    return translations[originalWord];
  }

  const key = originalWord.toLowerCase();
  const translatedWord = ((word) => {
    // word is all uppercase
    if (originalWord === word.toUpperCase()) {
      return word.toUpperCase();
    }

    // initial character is uppercase
    if (originalWord[0] === originalWord[0].toUpperCase()) {
      return word[0].toUpperCase() + word.substring(1);
    }

    return word;
  })(translations[key] || key);

  translations[originalWord] = translatedWord;
  return translatedWord;
};

export default translate;
